/* Общие стили */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Courier New', Courier, monospace;
  line-height: 1.6;
  transition: background-color 0.5s ease, color 0.5s ease;
}

/* Темы */
body.theme-dark {
  background-color: #1a1a1a;
  color: #e0e0e0;
}

body.theme-light {
  background-color: #f5f5f5;
  color: #333;
}

body.animate {
  transition: background-color 0.5s ease, color 0.5s ease;
}

/* Контейнер приложения */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Шапка */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background-color: inherit;
}

.section-title {
  font-size: 1.5rem;
  font-weight: normal;
}

.hamburger {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: inherit;
}

/* Навигация */
.sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: inherit;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  padding: 2rem 1rem;
  transition: left 0.3s ease;
  z-index: 1000;
}

.sidebar.open {
  left: 0;
}

.sidebar ul {
  list-style: none;
}

.sidebar li {
  margin: 1rem 0;
}

.sidebar a {
  color: inherit;
  text-decoration: none;
  font-size: 1rem;
}

.sidebar a:hover {
  text-decoration: underline;
}

.prefix {
  color: #888;
  margin-right: 0.5rem;
}

/* Переключатели */
.switches {
  display: flex;
  gap: 1rem;
}

.theme-toggle,
.switches button {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.theme-toggle.animate {
  transform: rotate(180deg);
}

/* Основной контент */
.content {
  flex: 1;
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}

/* Telegram кнопка */
.telegram-sticky {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: inherit;
  text-decoration: none;
  z-index: 1000;
}

.telegram-sticky:hover {
  opacity: 0.8;
}

/* Стили компонентов */
.profile-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 1rem;
  object-fit: cover;
}

.case-item {
  margin-bottom: 2rem;
}

.case-item h2 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.tags {
  display: flex;
  gap: 0.5rem;
  list-style: none;
  flex-wrap: wrap;
}

.tags li {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  font-size: 0.9rem;
}

.footer {
  text-align: center;
  padding: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* Медиа-запросы для адаптивности */
@media (min-width: 768px) {
  .hamburger {
    display: none;
  }

  .sidebar {
    position: static;
    width: 200px;
    height: auto;
    border-right: none;
    padding: 1rem;
  }

  .app {
    flex-direction: row;
  }

  .content {
    margin-left: 200px;
  }
}